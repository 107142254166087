<template>
  <div class="papel_user">
    <div class="table_servis_list">
        <table class="table mt-4" v-loading="loadingData">
          <thead>
            <tr>
              <th class="wt5">{{ $t('message.pp') }}</th>
              <th>{{ $t('message.payment_type') }}</th>
              <th class="wt7">{{ $t('message.amount') }}</th>
              <th class="wt10">{{ $t('message.currency') }}</th>
              <th class="wt10">{{ $t('message.rate') }}</th>
              <th class="wt7">{{ $t('message.date_0') }}</th>
              <th class="wt10">{{ $t('message.comment') }}</th>
              <th class="wt10">{{ $t('message.user') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ser,index) in list" :key="'order-create-in-header'+index">
              <td>{{ index + 1 }}</td>
              <td>
                {{ ser.payment_type }}
              </td>
              <td>
                {{ ser.amount | formatMoney}}
              </td>
              <td>
                {{ ser.currency }}
              </td>
              <td>
                {{ ser.rate  }}
              </td>
              <td>
                {{ ser.created_at}}
              </td>
              <td>
                {{ ser.comment }}
              </td>
              <td>
                {{ ser.user.name}} {{ ser.user.surname}}
              </td>
            </tr>
          </tbody>
        </table> 
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loadingData: false,
      activeNames: [""]
    };
  },
  computed: {
    getId() {
      return this.$route.params.id;
    },
    ...mapGetters({
      lastHistory: 'dailyTreatments/lastHistory',
      activeCode: 'hospitalPatients/activeCode',
      list: 'patientTransactions/list',
    }),
    actions: function () {
      return ['edit', 'delete'];
    }
  },
  watch: {
    lastHistory: {
      handler: function (val, oldVal) {
        this.loadingData = true;
        this.editModel({
            hospital_patient_id: this.getId,
            id: val.id
          })
          .then(res => {
            this.loadingData = false;
          }).catch(err => {
            this.loadingData = false;
            this.$alert(err)
          });
      }
    }
  },
  async mounted() {
    this.loadingData = true;
    var vm = this;
    this.editModel({
        hospital_patient_id: vm.getId,
        id: this.lastHistory.id
      })
      .then(res => {
        this.loadingData = false;
      }).catch(err => {
        this.loadingData = false;
        this.$alert(err)
      });
  },
  methods: {
    ...mapActions({
      editModel: 'patientTransactions/indexitems',
    }),
  },
};
</script>
<style lang="scss">
.papel_user .table_servis_list .wt10 {
  width: 12%;
}
.papel_user {
  .table_servis_list {
    padding: 0px;
  }
}
</style>