<template>
  <div class="mt-1">
    <div>
      <div class="title_resultsPatsnt">
        <div class="title_resultsPatsnt_text">{{ $t('message.patient_balance') }}</div>
        <div class="align-items-center align-self-center text-right pr-0 mb-2">
          <span class="align-items-center align-self-center pr-2">
            <el-tag>{{ $t('message.total') }}: {{modal_balance ? modal_balance.total_amount : 0 | formatMoney}} {{ $t('message.sum') }}</el-tag>
          </span>
          <span class="align-items-center align-self-center pr-2">
            <el-tag type="success">{{ $t('message.paid') }}: {{modal_balance ? modal_balance.paid_up : 0 | formatMoney}} {{ $t('message.sum') }}</el-tag>
          </span>
          <span class="align-items-center align-self-center pr-2">
            <el-tag type="warning">{{ $t('message.not_paid') }}: {{modal_balance ? modal_balance.not_paid : 0 | formatMoney}} {{ $t('message.sum') }}</el-tag>
          </span>
        </div>
      </div>
      <div class="nurseOffice_tabs" v-loading="loadingData">
        <el-tabs type="border-card">
          <el-tab-pane :label="$t('message.all_service')">
            <BalanceUslugaList />
          </el-tab-pane>
          <!-- end  el-tab-pane  -->
          <el-tab-pane :label="$t('message.history_transaction')">
            <BalanceList />
          </el-tab-pane>
          <!-- end  el-tab-pane  -->
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import BalanceUslugaList from "./components/balanceUslugaList";
import BalanceList from "./components/balanceList";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "clientBalance",
  components: {
    BalanceUslugaList,
    BalanceList
  },
  data() {
    return {
      loadingData: false,
    };
  },
  computed:{
      getId() {
        return this.$route.params.id;
      },
      ...mapGetters({
          modal_balance: 'patientBalances/modal_balance',
          lastHistory: 'dailyTreatments/lastHistory',
      }),
      actions: function() {
        return ['edit', 'delete'];
      }
  },
  watch: {
    lastHistory: {
      handler: function (val, oldVal) {
        this.editModel({
            hospital_patient_id: this.getId,
            id: val.id
          })
          .then(res => {
          }).catch(err => {
            this.$alert(err)
          });
      }
    }
  },
  async mounted() {
    this.loadingData = true;
    var vm = this;
    this.editModel({
        hospital_patient_id: vm.getId,
        id: this.lastHistory.id
      })
      .then(res => {
        this.loadingData = false;
      }).catch(err => {
        this.loadingData = false;
        this.$alert(err)
      });
  },
  methods: {
    ...mapActions({
        editModel: 'patientBalances/showBalance',
        empty: 'patientBalances/empty',
        refreshData: 'patientBalances/refreshData',
        showModels: 'hospitalizationConclusions/indexitems',
    }),
  }

};
</script>
